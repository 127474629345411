<template>
  <div class="content">
    <div class="content__title content__title--party content__title--start">
      <router-link
        :to="{ name: 'profile-personal' }"
        class="button button--light button--no-indent button--small"
      >
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.70917 13.7082C7.61629 13.8013 7.50595 13.8752 7.38447 13.9256C7.263 13.976 7.13277 14.002 7.00125 14.002C6.86973 14.002 6.7395 13.976 6.61802 13.9256C6.49655 13.8752 6.38621 13.8013 6.29332 13.7082L0.293962 7.70884C0.200846 7.61595 0.126968 7.50562 0.0765611 7.38414C0.0261537 7.26266 0.000206594 7.13243 0.000206606 7.00091C0.000206617 6.86939 0.0261537 6.73916 0.0765611 6.61769C0.126969 6.49621 0.200846 6.38587 0.293962 6.29299L6.29333 0.293626C6.48108 0.105873 6.73573 0.00039471 7.00125 0.000394734C7.26677 0.000394757 7.52142 0.105873 7.70918 0.293626C7.89693 0.481379 8.00241 0.736028 8.00241 1.00155C8.00241 1.26707 7.89693 1.52172 7.70917 1.70948L2.41574 7.00091L7.70917 12.2923C7.80229 12.3852 7.87617 12.4956 7.92658 12.617C7.97698 12.7385 8.00293 12.8688 8.00293 13.0003C8.00293 13.1318 7.97698 13.262 7.92658 13.3835C7.87617 13.505 7.80229 13.6153 7.70917 13.7082Z" fill="#2B93E7"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16 7.00108C16 7.26627 15.8947 7.5206 15.7071 7.70811C15.5196 7.89563 15.2653 8.00098 15.0001 8.00098L2.00149 8.00098C1.7363 8.00098 1.48197 7.89563 1.29445 7.70811C1.10694 7.5206 1.00159 7.26627 1.00159 7.00108C1.00159 6.73589 1.10694 6.48156 1.29445 6.29405C1.48197 6.10653 1.7363 6.00119 2.00149 6.00119L15.0001 6.00119C15.2653 6.00119 15.5196 6.10653 15.7071 6.29405C15.8947 6.48157 16 6.73589 16 7.00108Z" fill="#2B93E7"/>
        </svg>
      </router-link>
      <h1 class="title title--big title--theme">Редактирование социальных сетей</h1>
    </div>
    <form @submit.prevent="onCheckForm">
      <app-grid>
        <template #item-1>
          <app-form-group label-for="vk" label="Вконтакте">
            <app-input
              v-model.trim="form.vk"
              id="vk"
              placeholder="Добавьте ссылку своего профиля ВКонтакте"
              :class="{ 'input--error': $v.form.vk.$error }"
              @change.native="$v.form.vk.$touch()"
            />
            <template #error>
              <div v-if="$v.form.vk.$dirty && !$v.form.vk.url">Значение должно быть ссылкой</div>
            </template>
          </app-form-group>
          <app-form-group label-for="instagram" label="Instagram">
            <app-input
              v-model.trim="form.instagram"
              id="instagram"
              placeholder="Добавьте ссылку своего профиля Instagram"
              :class="{ 'input--error': $v.form.instagram.$error }"
              @change.native="$v.form.instagram.$touch()"
            />
            <template #error>
              <div v-if="$v.form.instagram.$dirty && !$v.form.instagram.url">Значение должно быть ссылкой</div>
            </template>
          </app-form-group>
          <app-form-group label-for="facebook" label="Facebook">
            <app-input
              v-model.trim="form.facebook"
              id="facebook"
              placeholder="Добавьте ссылку своего профиля Facebook"
              :class="{ 'input--error': $v.form.facebook.$error }"
              @change.native="$v.form.facebook.$touch()"
            />
            <template #error>
              <div v-if="$v.form.facebook.$dirty && !$v.form.facebook.url">Значение должно быть ссылкой</div>
            </template>
          </app-form-group>
          <app-form-group label-for="youtube" label="YouTube">
            <app-input
              v-model.trim="form.youtube"
              id="youtube"
              placeholder="Добавьте ссылку своего профиля YouTube"
              :class="{ 'input--error': $v.form.youtube.$error }"
              @change.native="$v.form.youtube.$touch()"
            />
            <template #error>
              <div v-if="$v.form.youtube.$dirty && !$v.form.youtube.url">Значение должно быть ссылкой</div>
            </template>
          </app-form-group>
        </template>
        <template #item-2>
          <app-form-group label-for="ok" label="Одноклассники">
            <app-input
              v-model.trim="form.ok"
              id="ok"
              placeholder="Добавьте ссылку своего профиля Одноклассники"
              :class="{ 'input--error': $v.form.ok.$error }"
              @change.native="$v.form.ok.$touch()"
            />
            <template #error>
              <div v-if="$v.form.ok.$dirty && !$v.form.ok.url">Значение должно быть ссылкой</div>
            </template>
          </app-form-group>
          <app-form-group label-for="twitter" label="Twitter">
            <app-input
              v-model.trim="form.twitter"
              id="twitter"
              placeholder="Добавьте ссылку своего профиля Twitter"
              :class="{ 'input--error': $v.form.twitter.$error }"
              @change.native="$v.form.twitter.$touch()"
            />
            <template #error>
              <div v-if="$v.form.twitter.$dirty && !$v.form.twitter.url">Значение должно быть ссылкой</div>
            </template>
          </app-form-group>
          <app-form-group label-for="telegram" label="Telegram">
            <app-input
              v-model.trim="form.telegram"
              id="telegram"
              placeholder="Добавьте ссылку своего профиля Telegram"
              :class="{ 'input--error': $v.form.telegram.$error }"
              @change.native="$v.form.telegram.$touch()"
            />
            <template #error>
              <div v-if="$v.form.telegram.$dirty && !$v.form.telegram.url">Значение должно быть ссылкой</div>
            </template>
          </app-form-group>
          <app-form-group label-for="tiktok" label="Tiktok">
            <app-input
              v-model.trim="form.tiktok"
              id="tiktok"
              placeholder="Добавьте ссылку своего профиля Tiktok"
              :class="{ 'input--error': $v.form.tiktok.$error }"
              @change.native="$v.form.tiktok.$touch()"
            />
            <template #error>
              <div v-if="$v.form.tiktok.$dirty && !$v.form.tiktok.url">Значение должно быть ссылкой</div>
            </template>
          </app-form-group>
        </template>
      </app-grid>
      <app-cells>
        <app-button ref="submit" :disabled="$v.form.$error">Сохранить</app-button>
      </app-cells>
    </form>
  </div>
</template>

<script>
import { url } from 'vuelidate/lib/validators'

export default {
  name: 'ProfilePersonalAddressEdit',
  data() {
    return {
      form: {
        vk: '',
        instagram: '',
        facebook: '',
        youtube: '',
        ok: '',
        twitter: '',
        telegram: '',
        tiktok: '',
      },
    }
  },
  validations: {
    form: {
      vk: { url },
      instagram: { url },
      facebook: { url },
      youtube: { url },
      ok: { url },
      twitter: { url },
      telegram: { url },
      tiktok: { url },
    }
  },
  mounted() {
    this.$store.dispatch('profile/personal/GET_DATA')
      .then(response => {
        this.form.vk = response.data.vk
        this.form.instagram = response.data.instagram
        this.form.facebook = response.data.facebook
        this.form.youtube = response.data.youtube
        this.form.ok = response.data.ok
        this.form.twitter = response.data.twitter
        this.form.telegram = response.data.telegram
        this.form.tiktok = response.data.tiktok
      })
  },
  methods: {
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      this.$store.dispatch('profile/personal/PATCH_DATA', this.form)
        .then(() => {
          this.$refs.submit.preload = false
          this.$router.push({ name: 'profile-personal' })
          this.$notify({
            type: 'success',
            title: 'Уведомление',
            text: 'Данные успешно сохранены'
          })
        })
        .catch(error => {
          this.$refs.submit.preload = false
          for (const key in error.response.data) {
            if (typeof error.response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: error.response.data[key]
              })
            } else {
              this.$notify({
                type: 'error',
                text: error.response.data[key][0]
              })
            }
          }
        })
    },
  }
}
</script>
